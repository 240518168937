@charset "utf-8";

/* CUSTOM VARIABLE */
$color-base: #333333;
$color-brand: #428B3C;
$color-secondary: #e47071;
$color-third: #666666;
$color-tertiary:#C3B9B9;
$color-gray: #C6C6C6;
$color-link: #666666;
$color-button: #666666;
$color-hover: #4d4d4d;
$color-white: #FFF;

/*TRANSITION*/
$animate: all 0.3s ease;

/*BREAKPOINT*/
$sm: 767px;
$md: 1000px;
$lg: 1200px;
$xl: 1400px;
$xxl: 1600px;

/* Font */
$font-base: "游ゴシック体", "Yu Gothic", YuGothic, "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "MS Pゴシック", "MS PGothic", sans-serif;
$font-secondary: "ヒラギノ明朝 Pro", "Hiragino Mincho Pro", "MS P明朝", "MS PMincho", serif;